"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.STORE_NAME = exports.RESET_STORES = exports.MANUAL_ADD = exports.INITIAL_STATE = exports.FETCH_INGESTS_BY_WORK_REQUEST_SUCCESS = exports.FETCH_INGESTS_BY_WORK_REQUEST_REQUEST = exports.FETCH_INGESTS_BY_WORK_REQUEST_FAILURE = exports.FETCH_INGESTS_BY_WORK_REQUEST = void 0;
exports.fetchIngestsByWorkRequest = fetchIngestsByWorkRequest;
exports.internalActions = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchIngests = watchIngests;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.get"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _ingestService = _interopRequireDefault(require("~/app/3x/modules/services/ingest-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var RESET_STORES = 'session.reset-stores';
exports.RESET_STORES = RESET_STORES;
var STORE_NAME = 'ingestsStore';
exports.STORE_NAME = STORE_NAME;

function* fetchIngestsByWorkRequest(_ref) {
  var {
    payload
  } = _ref;
  var {
    workRequestId
  } = payload;

  try {
    yield (0, _effects.put)(internalActions.getIngestsByWorkRequestRequest({
      workRequestId
    }));
    var ingests = yield _ingestService.default.getIngestsForWorkRequest(workRequestId);
    yield (0, _effects.put)(internalActions.getIngestsByWorkRequestSuccess({
      workRequestId,
      ingests
    }));
    return ingests;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getIngestsByWorkRequestFailure({
      workRequestId,
      err
    }));
    return err;
  }
}

var FETCH_INGESTS_BY_WORK_REQUEST = 'ingests.fetch-by-work-request';
exports.FETCH_INGESTS_BY_WORK_REQUEST = FETCH_INGESTS_BY_WORK_REQUEST;
var FETCH_INGESTS_BY_WORK_REQUEST_REQUEST = 'ingests.fetch-by-work-request.request';
exports.FETCH_INGESTS_BY_WORK_REQUEST_REQUEST = FETCH_INGESTS_BY_WORK_REQUEST_REQUEST;
var FETCH_INGESTS_BY_WORK_REQUEST_SUCCESS = 'ingests.fetch-by-work-request.success';
exports.FETCH_INGESTS_BY_WORK_REQUEST_SUCCESS = FETCH_INGESTS_BY_WORK_REQUEST_SUCCESS;
var FETCH_INGESTS_BY_WORK_REQUEST_FAILURE = 'ingests.fetch-by-work-request.failure';
exports.FETCH_INGESTS_BY_WORK_REQUEST_FAILURE = FETCH_INGESTS_BY_WORK_REQUEST_FAILURE;
var MANUAL_ADD = 'ingests.add.manual';
exports.MANUAL_ADD = MANUAL_ADD;
var INITIAL_STATE = {
  ingests: {},
  ingestsByWorkRequest: {}
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case RESET_STORES:
      return INITIAL_STATE;

    case FETCH_INGESTS_BY_WORK_REQUEST_REQUEST:
      {
        var {
          workRequestId
        } = action.payload;
        var existingContainer = (0, _lodash2.default)(state.ingestsByWorkRequest, [workRequestId], {});
        return _objectSpread(_objectSpread({}, state), {}, {
          ingestsByWorkRequest: _objectSpread(_objectSpread({}, state.ingestsByWorkRequest), {}, {
            [workRequestId]: _objectSpread(_objectSpread({
              lastLoadedAt: null,
              data: null
            }, existingContainer), {}, {
              isLoading: true
            })
          })
        });
      }

    case FETCH_INGESTS_BY_WORK_REQUEST_SUCCESS:
      {
        var {
          workRequestId: _workRequestId,
          ingests
        } = action.payload;

        var updatedIngests = _objectSpread({}, state.ingests);

        var workRequestIngestIds = [];
        var now = new Date();

        _lodash.default.forEach(ingests, ingest => {
          updatedIngests[ingest._id] = {
            isLoading: false,
            lastLoadedAt: now,
            loadError: null,
            data: ingest
          };
          workRequestIngestIds.push(ingest._id);
        });

        return _objectSpread(_objectSpread({}, state), {}, {
          ingests: updatedIngests,
          ingestsByWorkRequest: _objectSpread(_objectSpread({}, state.ingestsByWorkRequest), {}, {
            [_workRequestId]: {
              isLoading: false,
              lastLoadedAt: now,
              loadError: null,
              data: workRequestIngestIds
            }
          })
        });
      }

    case FETCH_INGESTS_BY_WORK_REQUEST_FAILURE:
      {
        var {
          workRequestId: _workRequestId2,
          err
        } = action.payload;
        var _existingContainer = state.ingestsByWorkRequest[_workRequestId2];
        return _objectSpread(_objectSpread({}, state), {}, {
          ingestsByWorkRequest: _objectSpread(_objectSpread({}, state.ingestsByWorkRequest), {}, {
            [_workRequestId2]: _objectSpread(_objectSpread({}, _existingContainer), {}, {
              isLoading: false,
              loadError: err
            })
          })
        });
      }

    case MANUAL_ADD:
      {
        var {
          ingest
        } = action.payload;
        var _workRequestId3 = ingest.workRequest;

        var _now = new Date();

        var _existingContainer2 = (0, _lodash2.default)(state.ingestsByWorkRequest, [_workRequestId3], {
          isLoading: false,
          lastLoadedAt: null,
          data: []
        });

        return _objectSpread(_objectSpread({}, state), {}, {
          ingests: _objectSpread(_objectSpread({}, state.ingests), {}, {
            [ingest._id]: {
              isLoading: false,
              lastLoadedAt: _now,
              data: ingest
            }
          }),
          ingestsByWorkRequest: _objectSpread(_objectSpread({}, state.ingestsByWorkRequest), {}, {
            [_workRequestId3]: _objectSpread(_objectSpread({}, _existingContainer2), {}, {
              data: _lodash.default.uniq([..._existingContainer2.data, ingest._id])
            })
          })
        });
      }

    default:
      return state;
  }
}

var actions = {
  getIngestsByWorkRequest: (0, _reduxActions.createAction)(FETCH_INGESTS_BY_WORK_REQUEST),
  addIngestManually: (0, _reduxActions.createAction)(MANUAL_ADD)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  getIngestsByWorkRequestRequest: (0, _reduxActions.createAction)(FETCH_INGESTS_BY_WORK_REQUEST_REQUEST),
  getIngestsByWorkRequestSuccess: (0, _reduxActions.createAction)(FETCH_INGESTS_BY_WORK_REQUEST_SUCCESS),
  getIngestsByWorkRequestFailure: (0, _reduxActions.createAction)(FETCH_INGESTS_BY_WORK_REQUEST_FAILURE)
};
exports.internalActions = internalActions;
var selectors = {
  areWorkRequestIngestsLoading: (0, _common.createSelector)(STORE_NAME, areWorkRequestIngestsLoading),
  areWorkRequestIngestsLoaded: (0, _common.createSelector)(STORE_NAME, areWorkRequestIngestsLoaded),
  getWorkRequestIngestsLastLoadedAt: (0, _common.createSelector)(STORE_NAME, getWorkRequestIngestsLastLoadedAt),
  getWorkRequestIngests: (0, _common.createSelector)(STORE_NAME, getWorkRequestIngests)
};
exports.selectors = selectors;

function areWorkRequestIngestsLoading(state, workRequestId) {
  return (0, _lodash2.default)(state.ingestsByWorkRequest, [workRequestId, 'isLoading'], false);
}

function areWorkRequestIngestsLoaded(state, workRequestId) {
  return !!getWorkRequestIngestsLastLoadedAt(state, workRequestId);
}

function getWorkRequestIngestsLastLoadedAt(state, workRequestId) {
  return (0, _lodash2.default)(state.ingestsByWorkRequest, [workRequestId, 'lastLoadedAt'], null);
}

function getWorkRequestIngests(state, workRequestId) {
  var workRequestIngests = (0, _lodash2.default)(state.ingestsByWorkRequest, [workRequestId, 'data'], []);
  return _lodash.default.map(workRequestIngests, id => state.ingests[id].data);
}

function* watchIngests() {
  yield (0, _effects.takeEvery)(FETCH_INGESTS_BY_WORK_REQUEST, fetchIngestsByWorkRequest);
}