"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useAuth;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reactQuery = require("@tanstack/react-query");

var _userService = _interopRequireDefault(require("~/app/3x/modules/services/user-service"));

var _react = require("react");

var _contexts = require("~/app/3x/modules/contexts");

var _authService = _interopRequireDefault(require("~/app/3x/modules/services/auth-service"));

var _apiService = require("~/app/3x/modules/services/api-service");

var _routeService = _interopRequireDefault(require("~/app/3x/modules/services/route-service"));

var _common = require("~/app/3x/store/common");

var _sessionService = _interopRequireDefault(require("~/app/3x/modules/services/session-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function useAuth() {
  var {
    auth,
    setAuth
  } = (0, _react.useContext)(_contexts.SessionContext);
  /*
  	Login mutation
  */

  var {
    mutate: login,
    isLoading: isLoggingIn
  } = (0, _reactQuery.useMutation)({
    mutationKey: ['session'],
    mutationFn: _authService.default.login,
    onMutate: () => {
      setAuth(_objectSpread(_objectSpread({}, auth), {}, {
        lastAttemptedAt: new Date()
      }));
    },
    onSuccess: session => {
      if (session.twofactor) {
        setAuth(_objectSpread(_objectSpread({}, auth), {}, {
          isTwoFactorLogin: true
        }));
        return;
      }

      (0, _apiService.setupAPI)(session, true);
      setAuth({
        session,
        isLoggedIn: true,
        lastAttemptedAt: new Date()
      });

      _routeService.default.navigateTo('projects');
    },
    onError: e => {
      var err = (0, _common.handleError)(e.status === 403 ? {
        message: 'Invalid username or password'
      } : e);
      setAuth(_objectSpread(_objectSpread({}, auth), {}, {
        loginError: err
      }));
    }
  });
  /* Reset Password mutation */

  var {
    mutate: resetPassword,
    isLoading: isResettingPassword
  } = (0, _reactQuery.useMutation)({
    mutationKey: ['resetPassword'],
    mutationFn: _authService.default.resetPassword,
    onSuccess: () => {
      setAuth(_objectSpread(_objectSpread({}, auth), {}, {
        resetPasswordSuccess: true,
        resetPasswordError: null
      }));
    },
    onError: e => {
      setAuth(_objectSpread(_objectSpread({}, auth), {}, {
        resetPasswordSuccess: false,
        resetPasswordError: (0, _common.handleError)(e)
      }));
    }
  });
  /*
  	request Reset password mutation
  */

  var {
    mutate: requestResetPassword
  } = (0, _reactQuery.useMutation)({
    mutationKey: ['resetPassword'],
    mutationFn: _authService.default.requestResetPassword,
    onSuccess: () => {
      setAuth(_objectSpread(_objectSpread({}, auth), {}, {
        resetPasswordSuccess: true,
        resetPasswordError: null
      }));
    },
    onError: e => {
      setAuth(_objectSpread(_objectSpread({}, auth), {}, {
        resetPasswordSuccess: false,
        resetPasswordError: (0, _common.handleError)(e)
      }));
    }
  });
  /*
  	Reset password feedback
   */

  var clearResetPasswordFeedback = () => {
    setAuth(_objectSpread(_objectSpread({}, auth), {}, {
      resetPasswordSuccess: false,
      resetPasswordError: null
    }));
  };
  /*
  	Two factor login
   */


  var {
    mutate: twoFactorLogin,
    isLoading: isTwoFactorLoggingIn
  } = (0, _reactQuery.useMutation)({
    mutationKey: ['twoFactorLogin'],
    mutationFn: token => _authService.default.twoFactorLogin(token),
    onSuccess: _ref => {
      var _data$token;

      var {
        data
      } = _ref;
      setAuth(_objectSpread(_objectSpread({}, auth), {}, {
        twoFactorLoginSuccess: true
      }));
      login({
        username: (data === null || data === void 0 ? void 0 : data.emailNormal) || (data === null || data === void 0 ? void 0 : data.email) || '',
        token: (_data$token = data === null || data === void 0 ? void 0 : data.token) !== null && _data$token !== void 0 ? _data$token : '',
        isTwoFactor: true
      });
    },
    onError: e => {
      setAuth(_objectSpread(_objectSpread({}, auth), {}, {
        twoFactorLoginSuccess: false,
        twoFactorLoginError: (0, _common.handleError)(e).message
      }));
    }
  });
  /*
  	Resume session
   */

  var {
    mutateAsync: resumeSession
  } = (0, _reactQuery.useMutation)(['session'], () => _authService.default.resumeSession(), {
    onSuccess: session => {
      (0, _apiService.setupAPI)(session);
      setAuth(_objectSpread(_objectSpread({}, auth), {}, {
        session,
        isLoggedIn: true,
        lastAttemptedAt: new Date(),
        lastUserEmail: session.emailNormal
      }));
    },
    onError: () => {
      setAuth(_objectSpread(_objectSpread({}, auth), {}, {
        session: null,
        loginError: null,
        lastAttemptedAt: new Date()
      }));
    }
  });
  /*
  	Get session
  */

  var {
    mutateAsync: getSession
  } = (0, _reactQuery.useMutation)(['getSession'], () => _sessionService.default.getSession(), {
    onSuccess: session => {
      (0, _apiService.setupAPI)(session);
      setAuth(_objectSpread(_objectSpread({}, auth), {}, {
        session,
        isLoggedIn: true,
        isLoading: true,
        lastLoadedAt: new Date()
      }));
    },
    onError: () => {
      setAuth(_objectSpread(_objectSpread({}, auth), {}, {
        session: null,
        isLoading: false
      }));
    }
  });
  /*
  logout mutation
  */

  var {
    mutate: logout
  } = (0, _reactQuery.useMutation)({
    mutationKey: ['logout'],
    mutationFn: _authService.default.logout
  });
  /*
  	Update user mutation
  */

  var {
    mutateAsync: updateUser,
    isLoading: isUserSaving
  } = (0, _reactQuery.useMutation)(data => _userService.default.updateUser(auth.session.id, data));

  function isLoaded() {
    return !!auth.lastLoadedAt;
  }

  return _objectSpread(_objectSpread({}, auth), {}, {
    resumeSession,
    getSession,
    login,
    logout,
    twoFactorLogin,
    isTwoFactorLoggingIn,
    isUserSaving,
    updateUser,
    isLoggingIn,
    isLoaded,
    isResettingPassword,
    requestResetPassword,
    resetPassword,
    clearResetPasswordFeedback
  });
}