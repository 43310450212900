"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _reactQuery = require("@tanstack/react-query");

var _apiService = _interopRequireDefault(require("../../modules/services/api-service"));

var _shareService = _interopRequireDefault(require("../../modules/services/share-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var GET_SHARE_QUEUE = "\nquery getShareQueue {\n\tsession {\n\t\tid\n\t\tshareQueue {\n\t\t\tid\n\t\t\tmediaType\n\t\t}\n\t}\n}\n";

function useShareQueue() {
  var {
    isInitialLoading: isLoading,
    isError,
    data
  } = (0, _reactQuery.useQuery)(['getShareQueue'], () => _apiService.default.graphql.request(GET_SHARE_QUEUE));
  var session = data === null || data === void 0 ? void 0 : data.session;
  var sessionId = session === null || session === void 0 ? void 0 : session.id;
  var shareQueue = session === null || session === void 0 ? void 0 : session.shareQueue;
  var queryClient = (0, _reactQuery.useQueryClient)();
  var {
    mutateAsync: updateShareQueue
  } = (0, _reactQuery.useMutation)(queue => _shareService.default.updateShareQueue(sessionId, queue), {
    onMutate: function () {
      var _onMutate = (0, _asyncToGenerator2.default)(function* (newShareQueue) {
        yield queryClient.cancelQueries(['getShareQueue']);
        var previousShareQueue = queryClient.getQueryData(['getShareQueue']);
        queryClient.setQueryData(['getShareQueue'], oldData => {
          return _objectSpread(_objectSpread({}, oldData), {}, {
            session: _objectSpread(_objectSpread({}, oldData === null || oldData === void 0 ? void 0 : oldData.session), {}, {
              shareQueue: newShareQueue
            })
          });
        });
        return {
          previousShareQueue
        };
      });

      function onMutate(_x) {
        return _onMutate.apply(this, arguments);
      }

      return onMutate;
    }(),
    onError: (err, newShareQueue, context) => {
      queryClient.setQueryData(['getShareQueue'], context.previousShareQueue);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['getShareQueue']);
    }
  });
  return [updateShareQueue, shareQueue, {
    isLoading,
    isError
  }];
}

var _default = useShareQueue;
exports.default = _default;